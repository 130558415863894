/* === Bootstrap Global === */
$enable-rounded: true;

$site-wrapper-width: 100%; // 1920px;

/* === Header / Navigation === */
$header-height-desktop: 80px;
$extended-header-height-desktop: 80px;
$header-space: 10px;
$top-nav-height: 40px;
$top-nav-space: 20px;


/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #b9b9b9;
$color-orange: #ce6a06;
//$color-primary: #144282;
//$color-grey: #B5B5B5;


/* === Fonts === */
//$font-family-base: 'Assistant', sans-serif;
//$font-family-secondary: 'Assistant', sans-serif;
